import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var profileTypesData = {
    companies: {
        endPointAlias: "company",
        label: "Company Name/Ticker",
        completeNameKey: "completeName",
        titlePage: "Company Profiles",
        reportId: process.env.NEXT_PUBLIC_COMPANIES_PROFILE_REPORT_ID,
        workspaceId: process.env.NEXT_PUBLIC_WORKSPACE_ID,
        printReportId: process.env.NEXT_PUBLIC_COMPANIES_PROFILE_PRINT_REPORT_ID,
        pbiReportName: "Company Profile",
        defaultSectionName: "ReportSectionc5c26f762e654ad033f3",
        table: "Company",
        column: "Company Name",
        tabs: [],
        printPages: [],
        printFilter: "Company/Company_x0020_Name eq",
        showExportButton: true
    },
    targets: {
        endPointAlias: "moleculartargetandfamily",
        label: "Molecular Target Name",
        completeNameKey: "legacyTargetName",
        titlePage: "Target Profiles",
        reportId: process.env.NEXT_PUBLIC_TARGETS_PROFILE_REPORT_ID,
        workspaceId: process.env.NEXT_PUBLIC_WORKSPACE_ID,
        pbiReportName: "Disease and Target Profile",
        defaultSectionName: "ReportSection",
        table: "Molecular Target",
        column: "Molecular Target",
        printReportId: "",
        printPages: [],
        printFilter: "",
        showExportButton: true,
        tabs: [
            {
                name: "pipeline",
                displayName: "Pipeline",
                sectionName: "ReportSection92d0db842f6b8b4bc99b",
                sectionColumn: "Molecular Target Family",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "pipeline",
                displayName: "Pipeline",
                sectionName: "ReportSection92d0db842f6b8b4bc99b",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "deals",
                displayName: "Deals",
                isLocked: true,
                sectionName: "ReportSectiona4049018b7af67a7c6a5",
                sectionColumn: "Deal Molecular Target Family",
                sectionTable: "Deal Molecular Target",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "deals",
                displayName: "Deals",
                isLocked: true,
                sectionName: "ReportSectiona4049018b7af67a7c6a5",
                sectionTable: "Deal Molecular Target",
                sectionColumn: "Deal Molecular Target",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "financings",
                displayName: "Financings",
                isLocked: true,
                sectionTable: "Molecular Target",
                sectionName: "ReportSectione7dcb9c7b03d9aa508b2",
                sectionColumn: "Molecular Target Family",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "financings",
                displayName: "Financings",
                isLocked: true,
                sectionTable: "Molecular Target",
                sectionName: "ReportSectione7dcb9c7b03d9aa508b2",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "articles",
                displayName: "Articles",
                showArticles: true,
                showTombstone: false
            }, 
        ]
    },
    products: {
        endPointAlias: "product",
        label: "Product Name",
        completeNameKey: "completeName",
        reportId: process.env.NEXT_PUBLIC_PRODUCTS_PROFILE_REPORT_ID,
        workspaceId: process.env.NEXT_PUBLIC_WORKSPACE_ID,
        pbiReportName: "Pipeline Landing Page",
        defaultSectionName: "ReportSection3f4886e296feaaaeda85",
        titlePage: "Product Profiles",
        table: "Pipeline",
        column: "Product Name",
        printReportId: process.env.NEXT_PUBLIC_PRODUCTS_PROFILE_REPORT_ID,
        printPages: [
            "ReportSectiond804f8043f9bbfc22d09",
            "ReportSection5cf1f7cadf4f4536c1a6"
        ],
        printFilter: "Pipeline/Product_x0020_Name eq",
        showExportButton: true,
        tabs: [
            {
                name: "product",
                displayName: "Product",
                sectionName: "ReportSection3f4886e296feaaaeda85",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "deals",
                displayName: "Deals",
                isLocked: true,
                sectionName: "ReportSectionb205c577568e92ca16a0",
                sectionTable: "Deal Pipeline",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "articles",
                displayName: "Articles",
                showArticles: true,
                showTombstone: false
            }, 
        ]
    },
    diseases: {
        endPointAlias: "diseasecategoryandstandardindication",
        label: "Disease Category or Standard Indication Name",
        completeNameKey: "completeName",
        reportId: process.env.NEXT_PUBLIC_DISEASES_PROFILE_REPORT_ID,
        workspaceId: process.env.NEXT_PUBLIC_WORKSPACE_ID,
        pbiReportName: "Disease and Target Profiles",
        defaultSectionName: "ReportSection",
        table: "Disease",
        column: "Disease Category",
        titlePage: "Disease Profiles",
        printReportId: "",
        printPages: [],
        printFilter: "",
        showExportButton: true,
        tabs: [
            {
                name: "pipeline",
                displayName: "Pipeline",
                sectionName: "ReportSection877b5f7d1b51fd47afe6",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "pipeline",
                displayName: "Pipeline",
                sectionName: "ReportSection877b5f7d1b51fd47afe6",
                sectionColumn: "Standard Indication",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "deals",
                displayName: "Deals",
                sectionName: "ReportSectiona4049018b7af67a7c6a5",
                sectionTable: "Deal Disease",
                sectionColumn: "Deal Disease Category",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "deals",
                displayName: "Deals",
                sectionName: "ReportSectiona4049018b7af67a7c6a5",
                sectionTable: "Deal Disease",
                sectionColumn: "Deal Standard Indication",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "financings",
                displayName: "Financings",
                sectionName: "ReportSectione7dcb9c7b03d9aa508b2",
                sectionTable: "Lead Disease",
                sectionColumn: "Disease Category",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "financings",
                displayName: "Financings",
                sectionName: "ReportSectione7dcb9c7b03d9aa508b2",
                sectionTable: "Lead Disease",
                sectionColumn: "Standard Indication",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "articles",
                displayName: "Articles",
                showArticles: true,
                showTombstone: false
            }, 
        ]
    }
};
export var getProfileTypesData = function(param) {
    var type = param.type, archived = param.archived, companyType = param.companyType, isLoggedIn = param.isLoggedIn, dimension = param.dimension, _isMobile = param.isMobile, isMobile = _isMobile === void 0 ? false : _isMobile;
    var profile = profileTypesData[type];
    var articlesTab = {
        name: "articles",
        displayName: "Articles",
        showArticles: true,
        showTombstone: false
    };
    if (archived) {
        return Object.assign(Object.assign({}, profile), {
            showExportButton: false,
            tabs: [
                {
                    name: "overview",
                    displayName: "Overview",
                    showArticles: true,
                    showTombstone: true
                }, 
            ]
        });
    }
    if (type === "companies") {
        var remainingTabs = [
            {
                name: "pipeline",
                displayName: "Pipeline",
                isLocked: true,
                sectionName: "ReportSection0f3e8f6669c724a015f7",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "deals",
                displayName: "Deals",
                isLocked: true,
                sectionName: "ReportSection0d9ed4f5f96ae2a6c238",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "financings",
                displayName: "Financings",
                isLocked: true,
                sectionName: "ReportSectiond72c24ce2fa523a098d3",
                showArticles: false,
                showTombstone: false
            }, 
        ];
        var remainingPrintPages = [
            "ReportSectione4e0ae3b340e4618fe6a",
            "ReportSection5a368bbeea67ea38a4d2",
            "ReportSection4f63ac1e450f74783519"
        ];
        var summaryTabs = [
            {
                name: "earningsSummary",
                displayName: "Earnings Summary",
                isLocked: true,
                sectionName: "ReportSection515ed2b1b723dea02a09",
                showArticles: false,
                showTombstone: false
            },
            {
                name: "stockSummary",
                displayName: "Stock Summary",
                isLocked: true,
                sectionName: "ReportSection6f2ad24038a52da677d7",
                showArticles: false,
                showTombstone: false
            }, 
        ];
        var summaryPrintPages = [
            "ReportSectiona6d0830335b2f56a124b",
            "ReportSection5252604c40f07d5d7f3d"
        ];
        if (companyType === "AcquiredOrMerged") {
            var printPages = [
                "ReportSectionad99fcb684450954b768"
            ];
            if (isMobile) {
                return Object.assign(Object.assign({}, profile), {
                    reportId: process.env.NEXT_PUBLIC_PBI_MOBILE_COMPANY_REPORT,
                    workspaceId: process.env.NEXT_PUBLIC_PBI_MOBILE_WORKSPACE,
                    tabs: [
                        {
                            name: "overview",
                            displayName: "Overview",
                            sectionName: "ReportSectione90ecc2382a096c39d32",
                            showArticles: true,
                            showTombstone: true
                        },
                        articlesTab, 
                    ],
                    printPages: printPages
                });
            }
            return Object.assign(Object.assign({}, profile), {
                tabs: [
                    {
                        name: "overview",
                        displayName: "Overview",
                        sectionName: "ReportSection329fb6f40c4e451ed032",
                        showArticles: true,
                        showTombstone: true
                    },
                    articlesTab, 
                ],
                printPages: printPages
            });
        }
        if (companyType === "NonBiopharma") {
            return Object.assign(Object.assign({}, profile), {
                showExportButton: false,
                tabs: [
                    {
                        name: "overview",
                        displayName: "Overview",
                        showArticles: true,
                        showTombstone: true
                    }
                ]
            });
        }
        if (companyType === "PrivateBiopharma") {
            var printPages1 = [
                "ReportSection65d4a4010447d46457dd"
            ].concat(_to_consumable_array(remainingPrintPages));
            if (isMobile) {
                return Object.assign(Object.assign({}, profile), {
                    reportId: process.env.NEXT_PUBLIC_PBI_MOBILE_COMPANY_REPORT,
                    workspaceId: process.env.NEXT_PUBLIC_PBI_MOBILE_WORKSPACE,
                    tabs: [
                        {
                            name: "overview",
                            displayName: "Overview",
                            sectionName: "ReportSection9f8913d49da8a70563b7",
                            showArticles: true,
                            showTombstone: true
                        },
                        articlesTab, 
                    ],
                    printPages: printPages1
                });
            }
            if (isLoggedIn) {
                return Object.assign(Object.assign({}, profile), {
                    tabs: [
                        {
                            name: "overview",
                            displayName: "Overview",
                            sectionName: "ReportSection3e5efe0834bcc9b2c315",
                            showArticles: true,
                            showTombstone: true
                        }, 
                    ].concat(_to_consumable_array(remainingTabs), [
                        articlesTab, 
                    ]),
                    printPages: printPages1
                });
            }
            return Object.assign(Object.assign({}, profile), {
                tabs: [
                    {
                        name: "overview",
                        displayName: "Overview",
                        sectionName: "ReportSectione9b9e9d9036267da0c91",
                        showArticles: true,
                        showTombstone: true
                    }, 
                ].concat(_to_consumable_array(remainingTabs), [
                    articlesTab, 
                ]),
                printPages: printPages1
            });
        }
        if (companyType === "PublicBiopharma") {
            var printPages2 = [
                "ReportSection0231ea7096bfe355969a"
            ].concat(_to_consumable_array(remainingPrintPages), _to_consumable_array(summaryPrintPages));
            if (isMobile) {
                return Object.assign(Object.assign({}, profile), {
                    reportId: process.env.NEXT_PUBLIC_PBI_MOBILE_COMPANY_REPORT,
                    workspaceId: process.env.NEXT_PUBLIC_PBI_MOBILE_WORKSPACE,
                    tabs: [
                        {
                            name: "overview",
                            displayName: "Overview",
                            sectionName: "ReportSection",
                            showArticles: true,
                            showTombstone: true
                        },
                        articlesTab, 
                    ],
                    printPages: printPages2
                });
            }
            if (isLoggedIn) {
                return Object.assign(Object.assign({}, profile), {
                    tabs: [
                        {
                            name: "overview",
                            displayName: "Overview",
                            sectionName: "ReportSectionc5c26f762e654ad033f3",
                            showArticles: true,
                            showTombstone: true
                        }, 
                    ].concat(_to_consumable_array(remainingTabs), _to_consumable_array(summaryTabs), [
                        articlesTab, 
                    ]),
                    printPages: printPages2
                });
            }
            return Object.assign(Object.assign({}, profile), {
                tabs: [
                    {
                        name: "overview",
                        displayName: "Overview",
                        sectionName: "ReportSection61269a4e3a1333094280",
                        showArticles: true,
                        showTombstone: true
                    }, 
                ].concat(_to_consumable_array(remainingTabs), _to_consumable_array(summaryTabs), [
                    articlesTab, 
                ]),
                printPages: printPages2
            });
        }
        return Object.assign(Object.assign({}, profile), {
            showExportButton: false,
            tabs: [
                {
                    name: "overview",
                    displayName: "Overview",
                    showArticles: true,
                    showTombstone: true
                }
            ]
        });
    }
    if (type === "targets") {
        if (isMobile) {
            return Object.assign(Object.assign({}, profile), {
                reportId: process.env.NEXT_PUBLIC_PBI_MOBILE_TARGET_REPORT,
                workspaceId: process.env.NEXT_PUBLIC_PBI_MOBILE_WORKSPACE,
                tabs: [
                    Object.assign(Object.assign({}, profile.tabs[dimension === "moleculartargetfamily" ? 0 : 1]), {
                        sectionName: "ReportSection7569e324eb58092a88d0"
                    }),
                    profile.tabs[6]
                ]
            });
        }
        if (dimension === "moleculartargetfamily") {
            return Object.assign(Object.assign({}, profile), {
                tabs: [
                    profile.tabs[0],
                    profile.tabs[2],
                    profile.tabs[4],
                    profile.tabs[6]
                ]
            });
        }
        return Object.assign(Object.assign({}, profile), {
            tabs: [
                profile.tabs[1],
                profile.tabs[3],
                profile.tabs[5],
                profile.tabs[6]
            ]
        });
    }
    if (type === "diseases") {
        if (isMobile) {
            return Object.assign(Object.assign({}, profile), {
                reportId: process.env.NEXT_PUBLIC_PBI_MOBILE_DISEASE_REPORT,
                workspaceId: process.env.NEXT_PUBLIC_PBI_MOBILE_WORKSPACE,
                tabs: [
                    Object.assign(Object.assign({}, profile.tabs[dimension === "diseasecategory" ? 0 : 1]), {
                        sectionName: "ReportSection7569e324eb58092a88d0"
                    }),
                    profile.tabs[6]
                ]
            });
        }
        if (dimension === "diseasecategory") {
            return Object.assign(Object.assign({}, profile), {
                tabs: [
                    profile.tabs[0],
                    profile.tabs[2],
                    profile.tabs[4],
                    profile.tabs[6]
                ]
            });
        }
        return Object.assign(Object.assign({}, profile), {
            tabs: [
                profile.tabs[1],
                profile.tabs[3],
                profile.tabs[5],
                profile.tabs[6]
            ]
        });
    }
    if (type === "products") {
        if (isMobile) {
            return Object.assign(Object.assign({}, profile), {
                reportId: process.env.NEXT_PUBLIC_PBI_MOBILE_PRODUCT_REPORT,
                workspaceId: process.env.NEXT_PUBLIC_PBI_MOBILE_WORKSPACE,
                tabs: [
                    Object.assign(Object.assign({}, profile.tabs[0]), {
                        sectionName: "ReportSection7569e324eb58092a88d0"
                    }),
                    profile.tabs[2]
                ]
            });
        }
        return profileTypesData[type];
    }
    return profileTypesData[type];
};
