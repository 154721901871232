var PROFILE_TYPE;
(function(PROFILE_TYPE) {
    PROFILE_TYPE["TARGETS"] = "targets";
    PROFILE_TYPE["DISEASES"] = "diseases";
    PROFILE_TYPE["PRODUCTS"] = "products";
})(PROFILE_TYPE || (PROFILE_TYPE = {}));
var profileTabs = {
    targets: {
        endPointAlias: "moleculartargetandfamily",
        workspaceId: process.env.NEXT_PUBLIC_WORKSPACE_ID,
        reportId: process.env.NEXT_PUBLIC_DISEASES_AND_TARGETS_PROFILE_REPORT_ID,
        titlePage: "Target Profiles",
        moleculartarget: [
            {
                name: "Pipeline",
                filter: "Molecular_x0020_Target/Molecular_x0020_Target eq",
                pages: [
                    {
                        pageName: "ReportSection67cd2ac6ed276f10cc75"
                    }
                ]
            },
            {
                name: "Deals",
                filter: "Deal_x0020_Molecular_x0020_Target/Deal_x0020_Molecular_x0020_Target eq",
                pages: [
                    {
                        pageName: "ReportSection9458b9cee931c55e1973"
                    }
                ]
            },
            {
                name: "Financings",
                filter: "Lead_x0020_Target/Molecular_x0020_Target eq",
                pages: [
                    {
                        pageName: "ReportSection5616ceae0aa1bd5f6c31"
                    }
                ]
            }, 
        ],
        moleculartargetfamily: [
            {
                name: "Pipeline",
                filter: "Molecular_x0020_Target/Molecular_x0020_Target_x0020_Family eq",
                pages: [
                    {
                        pageName: "ReportSection3bfbb38d4e3da8c600d3"
                    }
                ]
            },
            {
                name: "Deals",
                filter: "Deal_x0020_Molecular_x0020_Target/Molecular_x0020_Target_x0020_Family eq",
                pages: [
                    {
                        pageName: "ReportSection9458b9cee931c55e1973"
                    }
                ]
            },
            {
                name: "Financings",
                filter: "Lead_x0020_Target/Molecular_x0020_Target_x0020_Family eq",
                pages: [
                    {
                        pageName: "ReportSection5616ceae0aa1bd5f6c31"
                    }
                ]
            }, 
        ]
    },
    diseases: {
        endPointAlias: "diseasecategoryandstandardindication",
        titlePage: "Disease Profiles",
        reportId: process.env.NEXT_PUBLIC_DISEASES_AND_TARGETS_PROFILE_REPORT_ID,
        workspaceId: process.env.NEXT_PUBLIC_WORKSPACE_ID,
        standardindication: [
            {
                name: "Pipeline",
                filter: "Disease/Standard_x0020_Indication eq",
                pages: [
                    {
                        pageName: "ReportSectionc3701c82eef2e8c06894"
                    }
                ]
            },
            {
                name: "Deals",
                filter: "Deal_x0020_Disease/Deal_x0020_Standard_x0020_Indication eq",
                pages: [
                    {
                        pageName: "ReportSection8fcc2bcf2d72fe43020e"
                    }
                ]
            },
            {
                name: "Financings",
                filter: "Lead_x0020_Disease/Standard_x0020_Indication eq",
                pages: [
                    {
                        pageName: "ReportSection30f5984de141ee899188"
                    }
                ]
            }, 
        ],
        diseasecategory: [
            {
                name: "Pipeline",
                filter: "Disease/Disease_x0020_Category eq",
                pages: [
                    {
                        pageName: "ReportSection3c13696811b4e51823b6"
                    }
                ]
            },
            {
                name: "Deals",
                filter: "Deal_x0020_Disease/Deal_x0020_Disease_x0020_Category eq",
                pages: [
                    {
                        pageName: "ReportSection8fcc2bcf2d72fe43020e"
                    }
                ]
            },
            {
                name: "Financings",
                filter: "Lead_x0020_Disease/Disease_x0020_Category eq",
                pages: [
                    {
                        pageName: "ReportSection30f5984de141ee899188"
                    }
                ]
            }, 
        ]
    },
    products: {
        endPointAlias: "product",
        workspaceId: process.env.NEXT_PUBLIC_WORKSPACE_ID,
        reportId: process.env.NEXT_PUBLIC_PRODUCTS_PROFILE_REPORT_ID,
        titlePage: "Product Profiles",
        product: [
            {
                name: "Products",
                filter: "Pipeline/Product_x0020_Name eq",
                pages: [
                    {
                        pageName: "ReportSectiond804f8043f9bbfc22d09"
                    }
                ]
            },
            {
                name: "Deals",
                filter: "Deal_x0020_Pipeline/Product_x0020_Name eq",
                pages: [
                    {
                        pageName: "ReportSection5cf1f7cadf4f4536c1a6"
                    }
                ]
            }, 
        ]
    }
};
export default profileTabs;
