import { useEffect, useState } from "react";
var useWindowSize = function() {
    var ref = useState({
        width: undefined,
        height: undefined
    }), windowSize = ref[0], setWindowSize = ref[1];
    useEffect(function() {
        if ("object" !== "undefined") {
            var handleResize = function() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };
            window.addEventListener("resize", handleResize);
            handleResize();
            return function() {
                return window.removeEventListener("resize", handleResize);
            };
        }
    }, []);
    return windowSize;
};
export default useWindowSize;
