import axios from "axios";
export var getPowerBIAccessToken = function(token) {
    var endpoint = "".concat(process.env.NEXT_PUBLIC_API_URL, "/api/pbi/token");
    return axios.get(endpoint, {
        headers: {
            Authorization: "Bearer ".concat(token)
        }
    });
};
export var getPowerBIToken = function(reportId, workspaceId) {
    var mode = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "view";
    var endpoint = "".concat(process.env.NEXT_PUBLIC_API_URL, "/api/PBIReport/GetReportById/").concat(reportId, "/").concat(workspaceId, "/").concat(mode);
    return axios.get(endpoint);
};
export var exportToFile = function(reportId, workspaceId, token, pages, filter, profileName) {
    var endpoint = "".concat(process.env.NEXT_PUBLIC_POWER_BI_REPORT_API, "/").concat(workspaceId, "/reports/").concat(reportId, "/ExportTo");
    return axios.post(endpoint, {
        format: "pdf",
        powerBIReportConfiguration: {
            reportLevelFilters: [
                {
                    filter: "".concat(filter, " '").concat(profileName, "'")
                }
            ],
            pages: pages.map(function(page) {
                return {
                    pageName: page
                };
            })
        }
    }, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer ".concat(token),
            "Content-type": "application/json"
        }
    });
};
export var getExportToFileStatus = function(reportId, workspaceId, exportId, token) {
    var endpoint = "".concat(process.env.NEXT_PUBLIC_POWER_BI_REPORT_API, "/").concat(workspaceId, "/reports/").concat(reportId, "/exports/").concat(exportId);
    return axios.get(endpoint, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer ".concat(token)
        }
    });
};
export var getExportToFileData = function(reportId, workspaceId, exportId, token) {
    var endpoint = "".concat(process.env.NEXT_PUBLIC_POWER_BI_REPORT_API, "/").concat(workspaceId, "/reports/").concat(reportId, "/exports/").concat(exportId, "/file");
    return axios.get(endpoint, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer ".concat(token)
        },
        responseType: "arraybuffer"
    });
};
export var exportSharedFile = function(encodedName, type, fileName, token) {
    return axios.post(process.env.NEXT_PUBLIC_ORCHESTRATOR_API, {
        encodedName: encodedName,
        type: type,
        token: token,
        fileName: fileName
    }, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json"
        }
    });
};
