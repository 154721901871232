import axios from "axios";
export var getArticles = function() {
    var terms = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", type = arguments.length > 1 ? arguments[1] : void 0, pageSize = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0, pageIndex = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : 0;
    var params = getParams(terms, type, pageSize, pageIndex);
    return axios.get("".concat(process.env.NEXT_PUBLIC_ARTICLE_URL, "?").concat(params));
};
var getParams = function(terms, type, pageSize, pageIndex) {
    var params = {
        queryTerms: String(terms),
        sort: "relevance",
        pageIndex: String(pageIndex ? pageIndex : 0),
        pageSize: String(pageSize ? pageSize : 10),
        termType: termsByType[type] || ""
    };
    return new URLSearchParams(params).toString();
};
var termsByType = {
    companies: "cn",
    products: "pn",
    targets: "mt",
    diseases: "dc"
};
