import { profileTypesData } from "@utils/helpers/profile-data";
import axios from "axios";
export var getProfileSuggestion = function() {
    var keyword = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", endPointAlias = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "", pagesize = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 200;
    var encodedKeyword = encodeURIComponent(keyword);
    var endpoint = "".concat(process.env.NEXT_PUBLIC_API_URL, "/search/v1/profile/").concat(endPointAlias, "/suggest/").concat(endPointAlias == "company" ? "search/" : "").concat(encodedKeyword, "?pagesize=").concat(pagesize);
    return axios.get(endpoint);
};
export var getProfileByEncodedName = function() {
    var encodedName = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", type = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "";
    var uriEncodedName = encodeURIComponent(encodedName);
    var endpoint = "".concat(process.env.NEXT_PUBLIC_API_URL, "/search/v1/profile/").concat(profileTypesData[type].endPointAlias, "/url/").concat(uriEncodedName);
    return axios.get(endpoint);
};
